:root {
	--circle-size: clamp(32px, 32px, 32px);
	--spacing: clamp(0.25rem, 2vw, 0.5rem);
  }

		.Modal.open {
			animation: bounce-in 1s ease-in;
		}
	
		.Modal.close {
			animation: bounce-out 1s ease-in;
		}
	
		@keyframes bounce-in {
			0% {
				opacity: 0;
				transform: scale(0.5);
			}
	
			100% {
				opacity: 1;
				transform: scale(1);
			}
		}
	
		@keyframes bounce-out {
			0% {
				opacity: 1;
				transform: scale(1);
			}
	
			100% {
				opacity: 0;
				transform: scale(0.5);
			}
		}

.separador-vertical {
	height: 46px;
	width: 1px;
	background: #ECECEC;
	margin: auto 16px;
	display: none;
}


.SwiperSlide-container {
	height: 240px;
}

.insurances-container{
	background-color: #F0F0F0;
	padding: 48px 16px;

	.content-insurance{
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: 24px;
	}
	.card-insurance{
		border-radius: 12px;
		background-color: #FFFFFF;
		padding: 24px 48px;
		text-align: center;
		max-width: 480px;
		img {
			margin-bottom: 24px;
		}
		span, p{
			color: #222222;
			line-height: 24px;
		}
		span{
			font-family: "Str-Headline-Bold", sans-serif;
			margin-bottom: 16px;
			font-size: 20px;
			font-weight: 700;
			display: block;
			padding: 0 6px;
		}
		p {
			font-family: "Str-Microtext-Regular";
			font-size: 16px;
			font-weight: 400;
			margin: 0;
		}
	}
}



.span-surgir {
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-decoration-skip-ink: none;
	text-underline-position: from-font;
	color: #EC0000 !important;
	cursor: pointer;
	 a {
		color: #EC0000 !important;
	 }
}

.title-safe {
	color: #222222;
	font-size: 28px;
	line-height: 32px;
	text-align: center;
	// margin-bottom: 56px;
}

.description-protect-percentage {
	font-size: 16px;
	color: #222222;
	font-family: 'Str-Microtext-Light', sans-serif;
	text-align: center;
	margin-top: 24px;
	margin-bottom: 24px;
	line-height: 24px;
}

.section-benefits {
	padding: 96px 0px;

	&__cards {
		margin-top: 48px;
        .swiper-button-next, .swiper-button-prev{
			top: 30%;
		}
		&__card {
			
			color: #444444;
			font-size: 16px;
			line-height: 24px;
			font-family: "Str-Microtext-Regular", sans-serif;
			height: 220px !important;
			display: flex;
			align-items: top;
			justify-content: center;

			div {
				text-align: center;
			}

			img {
				width: 64px !important;
				height: 64px;
				// margin: auto;
			}
			p {
				width: 184px;
				padding: 0px 8px;
				// margin: auto;
    		margin-top: 12px;
				line-height: 24px;
			}
		}
	}
}

.section-toppings-porc {
	padding: 96px 0px;
	background-color: #F6FAFC;
	// &__carousel-toppings {
	// 	padding-left: 16px;
	// 	display: grid ;
	// 	justify-content: right
	// }

	&__cards {
		display: grid;
		grid-template-columns: auto auto auto;
		gap: 16px;

		&__card {
			width: 350px;
			margin: 0px auto;
			border-radius: 16px;
			background-color: #FFFFFF;
			padding: 16px;
			display: flex;
			&__icon {
				margin-right: 16px;
			}

			&__content {

				h5 {
					font-family: "Str-Headline-Bold", sans-serif;
					color: #222222;
				}
				p {
					font-size: 14px;
					color: #444444;
					font-family: "Str-Microtext-Regular", sans-serif;
					margin-bottom: 0px;
				}
			}
			&__content_acordion {
				display: flex;
				align-items: center;
				justify-content: space-between;
				flex: 1;
				gap: 16px;
				h5 {
					font-family: "Str-Headline-Bold", sans-serif;
					color: #222222;
				}
			}
		}
	}

	&__footer {
		margin-top: 36px;
		text-align: center;
		color: #222222;
		font-family: "Str-Microtext-Regular", sans-serif;
		border-top: 1px solid #E2EFF4;
		padding-top: 16px;
		line-height: 24px;

		span {
			color: #23779A;
			text-decoration: underline;
			cursor: pointer;
		}
	}

	&__notice {
		font-size: 14px;
		line-height: 20px;
		color: #727272;
		font-family: 'Str-Microtext-Light';
		text-align: center;
		margin-top: 16px;
	}
}

table, th, td {
    border: none !important;
		margin: 0px;   
		border-top-left-radius: 0px;
}

.table > thead > tr > th {
	vertical-align: middle !important;
	// padding-top: 32px !important;
	padding-bottom: 32px !important;
}

.table > tbody > tr > td {
	border-top: 1px solid #CEDEE7 !important;
	padding-top: 32px !important;
	padding-bottom: 32px !important;
	vertical-align: middle !important;
}

.section-sure {
	padding: 48px 0px;
	background-color: #FFFFFF;

	&__cards {
		margin: 48px auto;
		display: flex;
		flex-wrap: wrap;
		grid-gap: 24px;
		justify-content: center;

		&__card {
			width: 460px;
			border: 1px solid;
			padding: 16px 24px;
			border-radius: 12px;
			text-align: center;
			

			&__text {
				margin-bottom: 0px;
				font-size: 16px;
				line-height: 24px;
				font-family: 'Str-Microtext-Regular';
				color: #000000;
			}

			&__des {
				color: #444444;
				font-family: "Str-Microtext-Regular", sans-serif;
				font-size: 14px;
				font-weight: 100;
			}

			&__title {
				font-size: 24px;
				line-height: 32px;
				font-family: Str-Headline-Bold, sans-serif;
			}
		}

		&__first {
			border-color: #9E3667;
			color: #9E3667;
		}

		&__second {
			border-color: #16999D;
			color: #16999D;
		}

		&__thirth {
			
			border-color: #137E84;
			color: #137E84;
		}

	}
}

.section-plans-porc {
	padding: 48px 0px;
	background-color: #FFFFFF;

	&__link {
		display: flex;
    justify-content: center;

		&__body {
			cursor: pointer;
			width: max-content;
			justify-content: center;
			display: flex;
			align-items: center;
			color:#EC0000;
			h6 {
				font-family: "Str-Microtext-Regular", sans-serif;
				margin-bottom: 0px;
				text-decoration: underline;
				text-decoration-thickness: 1px;
				text-decoration-skip-ink: none;
				text-underline-position: from-font;
			}
			span {
				margin-left: 8px;
			}
		}
	}

	&__table {

		&__head {
			&__title {
				color: #222222;
				font-size: 28px;
				width: 150px;
				font-family: "Str-Headline-Bold", sans-serif;
			}
		}

		&__body {
			&__title {
				display: flex;
				align-items: center;
				color: #222222;
				font-weight: 700;
				font-family: "Str-Microtext-Bold", sans-serif;
				// width: max-content;

				img {
					margin-right: 8px;
				}

				span {
					min-width: fit-content;
				}
			}

			&__content {
				text-align: center;
				font-family: "Str-Microtext-Regular", sans-serif;
				&__title {
					font-size: 14px;
					color: #444444;
				}

				&__first {
					color: #9E3667;
				}

				&__second {
					color: #16999D;
				}
				&__thirth {
					color: #137E84;
				}
			}
		}
	}	

	&__conditions {
		color: #8F8F8F;
		font-family: "Str-Microtext-Regular", sans-serif;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 24px;
	}

	.container {
		padding: 0 16px;
	}
}

.section-operation {
	padding: 96px 0px;
	background-color: #F6FAFC;

	&__body {
		grid-template-columns: 1fr 1fr;
		display: grid;
		grid-gap: 80px;
		margin-top: 48px;
		&__steppers {
			margin: auto;

			ol {
				padding-left: 0pc;
				margin-bottom: 0px;
			}

			&__item {
				position: relative;
				display: flex;
				gap: 1rem;
				
			
				&:before {
				content:	attr(data-content);
					flex: 0 0 var(--circle-size);
					height: var(--circle-size);
					border-radius: 50%;
					border: 1px solid #EC0000;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					color: #444444;
					font-family: "Str-Microtext-Bold", sans-serif;
				}
			
				&:not(:last-child) {
					&:after {
						content: "";
						position: absolute;
						left: 0;
						top: calc(var(--circle-size) + var(--spacing));
						bottom: var(--spacing);
						// z-index: -1;
						transform: translateX(calc(var(--circle-size) / 2));
						width: 1px;
						background-color: #CCCCCC;
					}
					padding-bottom: 32px;
					
				}

			}

			&__content {
				color: #444444;
				
				&__title {
					font-family: "Str-Headline-Bold", sans-serif;
					
				}

				&__description {
					font-family: "Str-Microtext-Regular", sans-serif;
					line-height: 24px;
					margin-bottom: 0px;
				}
			}
		}

		&__image {
			img {
				width: 100%;
			}
		}
	}
}

.section-protect {
	padding: 48px 0px;
	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__cards {
		margin-top: 48px;
		&__card {
			background-size: cover;
			height: 360px;
			position: relative;

			
			&__content {
				position: absolute;
				bottom: 0px;
				padding: 16px;
			}
		}
	}
}

.section-information {
  background-color: #FFFFFF;
	padding: 96px 0px;

	&__tabs {
		margin-top: 30px;
		&__tab-requirements {
			border: 1px solid #CCCCCC;
			border-radius: 8px;
			padding: 16px;

			ul {
				padding-left: 1.5rem;
				margin-bottom: 0px;
				font-family: "Str-Microtext-Regular", sans-serif;
				color: #444444;
			}
			li {
				list-style-type: initial;
			}
		}

		&__list-order {
			li {
				list-style-type: auto !important;
			}
		}

		&__list-no-order {
			ul {
				padding-left: 2rem;
				margin-bottom: 0px;
				font-family: "Str-Microtext-Regular", sans-serif;
				color: #444444;
			}
			li {
				list-style-type: initial;
			}
		}

		&__link {
			display: flex;
    	margin-top: 28px;

			&__body {
				cursor: pointer;
				width: max-content;
				justify-content: center;
				display: flex;
				align-items: center;
				color:#EC0000;
				border: none;
				background-color: #FFFFFF;
				h6 {
					margin-bottom: 0px;
					text-decoration: underline;
					text-decoration-thickness: 1px;
					text-decoration-skip-ink: none;
					text-underline-position: from-font;
				}
				span {
					margin-left: 8px;
				}
			}
		}

		
		.accordion-item {
			background-color: #F6FAFC !important;
			border: 1px solid #767676 !important;

		}

		.accordion-button:hover {
			background-color: #ECF5F8 !important;
		}
		
		.accordion-button {
			font-family: "Str-Microtext-Bold", sans-serif !important;
			color: #444444 !important;
			background-color: #F6FAFC;
			border-radius: 8px !important;
			font-size: 16px !important;
		}
		.accordion-body {
			background-color: #FFFFFF !important;
			color: #444444;
			border-radius: 8px;
		}

		.accordion-button::after {
			background-image: url("../../../public/icons/chevron-down.svg") !important;
			display: block !important;
		}
		
		.accordion-button:not(.collapsed)::after {
			background-image: url("../../../public/icons/chevron-up.svg") !important;
			
		}

		.accordion-button:not(.collapsed) {
			color: #444444;
		}
	}

	&__tabs-responsive {
		margin-top: 48px;
		.accordion-button {
			color: #727272;
			font-size: "Str-Headline-Regular";
		}
	}

	.container {
		padding: 0 16px;
		.title-safe {
			padding: 0 5px;
		}
	}
}

.section-contact {
	background-color: #F5F9FB;
	padding: 64px 0px;

	&__body {
		display: flex;
		flex-wrap: wrap;
		max-width: 480px;

		&__content {
			color: #444444;
			max-width: 700px;
			margin: auto 0px;
			width: 100%;
			&__title {
				font-size: 28px;
				color: #222222;
				font-family: "Str-Headline-Bold", sans-serif;
				line-height: 32px;
				text-align: center;
			}

			&__description {
				margin: 24px auto;
				font-size: 18px;
				line-height: 24px;
				text-align: center;
				font-family: "Str-Microtext-Regular", sans-serif;
				&__phones {
					justify-content: center;
					flex-wrap: wrap;
					.phone-detail{
						width: 100%;
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						&:first-child {
							margin-bottom: 16px;
						}
						&:last-child {
							margin-bottom: 17px;
						}
						span {
							display: block;
							margin-bottom: 8px;
							width: 100%;
							text-align: center;
						}
					}
				}
			}

			span {
				font-size: 12px;
			}

			&__span {
				font-family: 'Str-Microtext-Light', sans-serif;
				font-weight: 400;
				font-size: 16px;
				padding: 12px 24px;
				p{
					margin: 0;
				}
				border: 1px solid #CC0000;
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 10px;
				color: #EC0000;
				border-radius: 100px;
			}
		}

		&__image {
			display: flex;
			align-items: center;
			justify-content: end;


			img {
				width: 100%;
			}
		}
	}

	.container {
		padding: 0 16px;
		display: flex;
		justify-content: center;
	}
}

.section-simulator-credit{
	background-color: #F5F9FB;
	padding: 64px 16px;
	img{
		width: 100%;
	}
	
	.information-simulation{
		div:first-child {
			text-align: center;
			margin-bottom: 40px;
			.title-safe {
				line-height: 32px;
				margin-bottom: 24px;
				display: block;
			}
			p {
				margin: 0;
				font-family: "Str-Microtext-Regular";
				font-size: 18px;
				line-height: 24px;
			}
		}

		.buttons-simalators{
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			gap: 16px;
			margin-bottom: 32px;
			button {
				background-color: #ffffff;
				color: #EC0000;
				border: 1px solid #EC0000 !important;
				font-weight: 700;
			}
		}
	}
}

.width-header-plan {
	width: 184px;
  height: auto;
}

//MOBILE
@media only screen and (max-width: 585px) {
	.table > thead {
		display: none;
	}

	.table > tbody > tr > td {
		border-top: none !important;
		padding: 0px !important;
	}

	.table > tbody > tr > td:nth-child(1) {
		padding-top: 32px !important;
		padding-bottom: 16px !important;
	}

	.table > tbody > tr:nth-child(even) {
		border-bottom: 1px solid #CEDEE7;
	}

	.table > tbody > tr:nth-child(even):last-child {
		border-bottom: none;
	}

	.table > tbody > tr:nth-child(even) > td  {
		padding-bottom: 16px !important;
	}


	.title-safe {
		font-size: 28px;
		padding: 0px 5px;
	}

	.section-benefits {
		padding: 64px 0px;
		&__cards {
			grid-template-columns: 1fr;
			&__card {
				// width: 48px;
			}
		}
		
	}

	.section-protect {
		padding: 48px 0px;
		&__cards {
			padding-left: 16px;
			width: 100%;
			height: 410px;
			display: grid;
			grid-auto-flow: column;
			overflow-x: scroll;
			margin-top: 48px;
			.card-producto {
				width: 280px;
				height: 378px;
			}
		}
		.card-producto{
			height: 378px;
		}
		.container {
			padding: 0 16px;
		.title-safe{
            padding: 0;
		}		}
	}

	.section-plans {
		padding: 64px 0px;
		&__cards {
			margin: 48px auto 12px auto;
			&__card {
				// padding: 8px;
			}
		}
	}

	.section-toppings-porc {
		padding: 48px 0px;
		&__carousel-toppings {
			padding-left: 16px;
		}

		&__footer {
			margin: 17px 18px 0px 18px
		}
	}

	.section-information {
		padding: 64px 0px;
	}
	
}

@media only screen and (max-width: 767px) {
	.section-operation {
		padding: 48px 0px;
		.container {
			padding: 0 16px;
		}
		&__body {
			display: flex;
  		flex-direction: column-reverse;
			grid-template-columns: 1fr;
			grid-gap: 48px;

			&__image {
				width: 100%;
				margin: auto;

				img {
					width: 100%;
				}
			}
		}
	}


}

@media only screen and (min-width: 320px) and (max-width: 575px) {
	.section-toppings-porc {
		padding: 48px 16px;
		&__cards {
			display: grid;
			grid-template-columns: auto;
			&__card {
				width: 100%;
			}
		}
	}

}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.section-toppings-porc {
		padding: 48px 64px;
		&__cards {
			display: grid;
			grid-template-columns: auto;
			&__card {
				width: 480px;
			}
		}
		&__footer {
			margin-top: 16px;
		}
		&__notice {
			padding: 0 30px;
		}
	}

	.title-toppings{
		padding: 0 125px;
	}

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.section-toppings-porc {
		padding: 64px 0px;
		&__cards {
			display: grid;
			grid-template-columns: auto auto;

			&__card {
				width: 342px;
			}
		}
	}

}

@media (min-width: 500px) {
	.section-simulator-credit{
		padding: 64px 143px;
	}
	.section-protect{
		.container {
			max-width: 700px;
		}
		&__cards {
			padding: 0;
			width: 100%;
			padding-left: 16px;
			.card-producto{
				margin-left: 0;
				width: 280px;
				height: 378px;
				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	.section-information {
		.container {
			max-width: 480px;
			padding: 0;
		}
	}

	.section-plans {
		&__cards {
			&__card {
              &__title {
				padding: 0 45px;
			  }
			}
	    }
    }

}

@media (min-width: 768px) {
	.section-simulator-credit{
		padding: 64px 40px;
		.container-simulation{
			display: flex;
			gap: 12px;
			div:last-child {
				width: 336px;
			}
		}
		.information-simulation{
			flex: 1;
			div:first-child {
				text-align: left;
				margin-bottom: 48px;
				span{
					text-align: left;
				}
			}
			.buttons-simalators{
				justify-content: flex-start;
				margin-bottom: 0;
			}
		}
	}

	.section-toppings-porc{
		&__cards{
			grid-template-columns: auto auto !important;
			&__card__content{
				padding-right: 34px;
			}
		}
		&__footer, &__notice {
			text-align: start;
		}

		&__footer{
			margin-top: 16px;
		}
	}
	.section-operation{
		padding: 64px 0px;
	}

	.section-protect{
		padding: 64px 0px;
		&__cards {
			padding-left: 48px;
			height: 406px;
			.card-producto {
				width: 320px;
				height: 378px;
				margin-top: 0;
			}
		}
		.container {
			padding: 0 18px;
		}
	}

	.title-safe {
		font-size: 32px;
		line-height: 48px;
	}

	.section-contact {
		&__body{
			width: 100%;
			flex-wrap: nowrap;
			max-width: initial;
			gap: 24px;
			&__content {
				max-width: 344px;
				&__title{
					font-size: 32px;
					line-height: 40px;
					text-align: start;
				}
				&__description {
					text-align: start;
					font-size: 18px;
					line-height: 24px;
					&__phones {
						gap: 15px;
						justify-content: flex-start;
						.phone-detail{
							width: auto;
							justify-content: flex-start;
							span {
								text-align: start;
								margin-bottom: 0;
							}
						}
					}
				}
				&__span {
					border: none;
					padding: 0;
					img {
						display: none;
					}
					p {
						font-weight: 700;
						font-size: 20px;
						color: #222222;
						line-height: 28px;
					}
				}
			}
			&__image {

				img {
					width: 280px;
				}
			}
		}
	}

	.section-information {
		.container {
			max-width: none;
			padding: 0;
		}
		.nav-tabs {
			margin: 48px 0 24px !important;
			padding: 0 8px;
		}
		.tab-content{
			padding:  0 24px 24px;
		}
		&__tabs {
			&__tab-requirements {
				padding: 24px;
			}
		}
	}

	.section-plans {
		padding: 64px 0;
		&__cards {
			justify-content: center;
			flex-wrap: initial;
			&__card {
              &__title {
				padding: 0 5px;
			  }
			  &__text {
				padding: 0 10px;
			  }
			}
	    }

		&__table{
			&__body {
				&__title {
					padding-right: 60px;
				}
			}
		}
    }

	.section-toppings-porc{
		&__cards{
			&__card__content{
				padding-right: 0;
			}
		}
	}
}

@media (min-width: 992px) {
  .container-toppings{
     width: 100%;
	 max-width: 855px;
  }

  .section-operation {
	.container {
		width: 855px;
	}
	&__body {
		grid-gap: 48px;
		&__image{
			width: 403px;
            height: 500px;
		}
		&__steppers {
			&__item:not(:last-child){
				padding-bottom: 24px;
			}
		}
	}
  }

  .section-protect{
	&__cards {
		margin: 48px 99px 0;
		width: auto;
		padding: 0;
		height: auto;
		.card-producto{
			margin-bottom: 0;
		}
	}
	.container {
		padding: 0;
	}
  }

  .section-contact {
	&__body {
		max-width: 855px;
		justify-content: space-between;
		padding-right: 40px;
		&__content {
			max-width: 427px;
		}
	}

  }

  .section-information {
	.container {
	   //max-width: 855px;
	   .title-safe{
		padding: 0;
	   }
	}
	.nav-tabs {
		padding: 0 178px;
	}
	.tab-content{
		padding: 0 172px 24px;
	}
}

.section-plans {
	.container {
		max-width: 855px;
	}
	&__cards {
		&__card {
			max-width: 460px;
			padding: 16px 10px !important;
		  &__title {
			padding: 0 35px;
		  }
		}
	}
}

.width-header-plan {
	width: 275px;
  height: auto;

	h5 {
		padding: 0;
	}
}
}


@media (min-width: 1199px) {
	.section-simulator-credit{
		.container-simulation{
			gap: 16px;
			justify-content: center;
			align-items: center;
			div:last-child {
				width: 419px;
			}
		}
		.information-simulation{
			width: 419px;
			flex: initial;
			div:first-child {
				span{
					line-height: 40px !important;
				}
			}
		}
		.img-simulator img{
			height: 281px;
		}
	}
}

@media (min-width: 1200px) {
	.section-simulator-credit{
		display: flex;
		justify-content: center;
		.container-simulation{
			padding: 0 2px;
			gap: 0;
			width: 100%;
			max-width: 1392px;
			justify-content: flex-start !important;
			.img-simulator {
				width: 560px !important;
				padding: 0 40px;
				img{
				  width: 480px;
				  height: 324px;
				 }
			}
		 }
		 .information-simulation{
			flex: 1;
		 }
	}


	.section-toppings-porc{
		&__cards{
			display: flex;
			flex-wrap: wrap;
			&__card {
				width: 464px;
			}
		}
	}

	.container-toppings{
		max-width: 945px;
	 }

	 .section-toppings-porc {
		padding: 64px 0px;
	 }

	 .insurances-container{
		padding: 64px 82px;
		.card-insurance{
            max-width: 460px;
			padding: 24px 82px;
			span {
				padding: 0;
			}
		}
	}

	.section-operation {
		.container {
			width: auto;
		}
		&__body {
			display: flex;
			&__image{
				width: 425px;
				height: 500px;
			}
			&__steppers {
				flex: 1;
				margin: 0;
			}
		}
	  }

	  .section-protect{
		&__cards {
			justify-content: center;
			margin: 48px 48px 0;
			.card-producto {
				width: 357px;
			}
		}
	}

	.section-contact {
		&__body {
			max-width: none;
		}
		.container {
			margin: 0;
			padding: 0px 40px;
			max-width: none;
		}
	}

	
	.section-information {
		.nav-tabs {
			padding: 0 8px;
		}
		.tab-content{
			padding: 0 24px 24px;
		}
	}

	.section-plans {
		.container {
			max-width: 1104px;
		}
		&__table {
			&__head {
				&__title {
					width: auto;
				}
			}
		}
	}
}

@media (min-width: 1440px) {
  .section-simulator-credit{
	.container-simulation{
		.img-simulator{
			width: 680px !important;
			img{
				width: 600px;
				height: 401px;
			}
		}
	}
  }


  .section-operation {
	display: flex;
    justify-content: center;
	.container {
		padding: 0;
        margin: 0 48px;
        width: 100%;
        max-width: 1392px;
	}
	&__body {
		&__image{
			height: 352px;
			img{
				height: 100%;
			}
		}
	}
  }

  .section-protect{
	&__cards {
		.card-producto {
			width: 437px;
		}
	}
  }

  .section-contact {
	&__body {
		max-width: 1392px;
	}
  }

  .section-information {
	.nav-tabs {
		padding: 0 48px;
	}
	.container {
		max-width: 1392px
	}
  }

  .section-plans {
	.container {
		max-width: 1344px;
	}
	&__cards {
		&__card {
			&__title {
				// padding: 0 15px;
			}
		}
	}
	.width-header-plan {
		width: 420px;
		height: auto;
		h5 {
			padding: 0 15px;
		}
	}
}
}


@media (min-width: 1500px) {
	.section-protect{
		&__cards {
			.card-producto {
				width: 453px;
			}
		}
	}

	.section-plans {
		.container {
			max-width: 1392px;
		}
	}
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
	.insurances-container{
		.card-insurance{
            max-width: 324px;
		}
	}
}

@media only screen and (min-width: 1025px) and (max-width: 1199px) {
	.insurances-container{
		.card-insurance{
            max-width: 415px;
		}
	}
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.section-toppings-porc {
		padding: 64px 100px;
		&__cards {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;

			&__card {
				width: 419px;
				margin: 0;
			}

		}
	}

}

@media only screen and (min-width: 2320px) {
  .section-toppings-porc {

		&__carousel-toppings {
			margin-top: 48px;
			justify-content: center
		}
	
	}
}