.card-producto {
    width: 357px;
    height: 378px;
    display: grid;
    place-items: end left;
    border-radius: 12px;
    margin: 16px;
}

.titulo-card-producto {
   font-family: "Str-Microtext-Regular";
   color: #ffffff;
   font-size: 20px;
   line-height: 24px;
   margin-bottom: 10px;
   font-weight: 700;
}

.descripcion-producto{
   margin-bottom: 10px;
   line-height: 20px;
   font-family: "Str-Microtext-Regular";
}
.home-arrow {
    margin-right: 4px;
    color: #e69e07;
    text-decoration:  underline;
    font-family: 'Str-Microtext-Light', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    &:hover {
        margin-right: 15px;
        color: #edbb50;
    }
}
.card-producto-gradient{
    padding: 30px 24px 24px;
    border-radius: 0px 0px 12px 12px;
     background: linear-gradient(180deg, #22222203,#22222299) ; 
}
.card-prospera {
    background-image: linear-gradient(180deg ,#22222200 0%,#22222202 30%,#22222295 55%,#222222 100%) ,
    url("../../../assets/images/Prospera Banner Home.png");
    background-position: top;
}
.card-mujeres {
    background-image: linear-gradient(180deg ,#22222200 0%,#22222203 30%,#22222299 50%,#222222 100%),
        url("../../../assets/images/Mujeres Unidas Banner Home.png");
        background-position: top;
}
.card-construyendo {
    background-image: linear-gradient(180deg ,#22222200 0%,#22222203 30%,#22222299 50%,#222222 100%),
        url("../../../assets/images/Construyendo Sueños Banner Home.png");
        background-position: top;
}
.card-desgravamen {
    background-image: linear-gradient(180deg ,#22222200 0%,#22222203 30%,#22222290 50%,#222222 100%),
        url("../../../assets/images/Desgravamen Banner Home.png");
        background-position: top;

}
.card-super {
    background-image: linear-gradient(180deg ,#22222200 0%,#22222203 30%,#22222299 50%,#222222 100%),
        url("../../../assets/images/Super Seguro Banner Home.png");
        background-position: top;
}

@media (max-width: 575px) {
    .card-producto {
        width: 280px;
        height: 360px;
        margin: 0px 16px 16px 0px;
    }
}
@media screen and (min-width: 575px) and (max-width: 767px) {
    .card-producto {
        width: 280px;
        height: 360px;
       
        margin: 30px 20px 10px 0px;
    }
}
@media screen and (min-width: 767px) and (max-width: 991px) {
    .card-producto {
        width: 279px;
        height: 360px;
        margin: 20px;
    }
}
@media screen and (min-width: 991px) and (max-width: 1199px) {
    .card-producto {
        width: 373px;
        height: 360px;
        border-radius: 12px;
        margin: 20px;
    }
}
