@import '../../../breakpoints_new';

.card-promotions {
  box-shadow: 0px 1px 6px 0px rgba(68, 68, 68, 0.20);
  background-color: #F5F9FB;
  border-radius: 12px;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden; 

  @media #{$mq-medium} {
    grid-template-columns: 1fr;
  }

  @media #{$mq-small} {
    grid-template-columns: 1fr;
  }

   &__hero {
     img {
      width: 100%;
      height: 100%;
      object-fit: cover; 
     }
  }

  &__content {
    padding: 24px;
    position: relative;

    @media #{$mq-medium} {
      position: inherit;
      margin: auto;
      width: 100%;
      height: 168px;
    }

    @media #{$mq-small} {
      position: inherit;
    }

    &__title {
      font-family: Str-Headline-Bold , sans-serif;
      font-size: 18px;
      color: #222222;
      line-height: 24px;

      @media #{$mq-medium} {
        font-size: 16px;
      }

      @media #{$mq-small} {
        font-size: 16px;
      }
    }

    &__description {
      margin: 16px auto;
      font-family: Str-Microtext-Regular, sans-serif;
      font-size: 14px;
      color: #727272;
    }

    button {
      position: absolute;
      bottom: 24px;

      @media #{$mq-medium} {
        position: inherit;
      }

      @media #{$mq-small} {
        position: inherit;
      }
    }
  }

}


.item {
  position: relative;
  aspect-ratio: 3/2; 
  overflow: hidden;

  @media #{$mq-medium} {
    position: inherit;
    aspect-ratio: 0;
  }

  @media #{$mq-small} {
    position: inherit;
    aspect-ratio: 0;
  }
}