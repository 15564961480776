.nav-tabs{
  margin-top: 30px;
  display: flex;
  .nav-item {
    flex-grow: 1;
    position: relative;
  }
  .nav-link {
    width: 100%;
    text-align: center;
  }
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  color:     #1d252d;
  text-decoration-line: underline;
  text-decoration-color: transparent;
  text-underline-offset: 8px;
  text-decoration-thickness: 4px;
  font-family: Str-Microtext-Regular , sans-serif;
  border-color: #ffffff #ffffff #ffffff !important;
  background-color: transparent !important;
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #EC0000;
    bottom: 0;
    left: 0;
  }
}

.requestHere {
	color: #EC0000 !important;
	font-family: "Str-Microtext-Regular", sans-serif !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
  color :#767676;
  font-family: Str-Microtext-Regular, sans-serif;
}

table, th, td {
  border: 1px solid #767676;
  border-collapse: collapse;
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;

}
.subtitulos{
   font-family:  'Str-Headline-Bold', sans-serif;
   color: #444444 ;
   font-size: 17px;
  
}
.card-style-transparence{
  box-sizing: border-box;
  padding: 24px 32px ;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  display: flex;
  width: 552px;
  margin-left: 5px;
}

.container-acordion-responsive {
  display: flex;
  justify-content: center;
  .acordion-sg{
    width: 100%;
    max-width: 480px;
  }
}

.title-legal {
  color: #1d252d;
  font-family: Str-Microtext-Regular, sans-serif;
  font-size: 16px;
  margin: 30px auto 16px auto;
}


@media (max-width: 600px) {
  #listProviders {
    font-size: 12px;
    td {
      padding: 4px !important;
    }
  }
}
