.nav-tabs{
  margin-top: 30px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
  color:     #1d252d;
  text-decoration-line: underline;

  text-underline-offset: 8px;
  text-decoration-thickness: 4px;
  font-family: Str-Microtext-Regular , sans-serif;
  border-color: #ffffff #ffffff #ffffff !important;
  background-color: transparent !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link{
  color :#767676;
  font-family: Str-Microtext-Regular, sans-serif;
}

table, th, td {
  border: 1px solid #767676;
  border-collapse: collapse;
  padding: 8px;
  margin-top: 8px;
  margin-bottom: 8px;

}
.subtitulos{
   font-family:  'Str-Headline-Bold', sans-serif;
   color: #444444 ;
   font-size: 17px;
   
}
.parrafo-bold-decoration{
  font-size: 16px;
  font-family: "Str-Headline-Bold", sans-serif;
  color:#444444;
  font-style: italic;
  text-decoration: underline;
  }   
.card-style-transparence{
  box-sizing: border-box;
  padding: 24px 32px ;
  border: 1px solid #CCCCCC;
  border-radius: 8px;
  display: flex;
  width: 552px;
  margin-left: 5px;
}