@import "../../../breakpoints_new";

.card-redirect {
  border: 1px solid #CEDEE7;
  border-radius: 12px;
  overflow: hidden; 
  width: calc((100% - 48px) / 3);
  box-shadow: 0px 1px 6px 0px #44444433;
  max-width: 370px;
  
  @media #{$mq-small} {
    width: 100%;
    margin: auto;
  }

  &__hero {
      position: relative;
      aspect-ratio: 3/2; 
      overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      
     }
  }

  &__content {
    padding: 24px;
    position: relative;
    height: 152px;

    @media #{$mq-medium} {
      padding: 16px;
      height: 212px;
    }

    @media #{$mq-small} {
      padding: 16px;
      height: auto;
    }

    &__title {
      color: #222222;
      font-family: 'Str-Headline-Bold', sans-serif;
      font-size: 20px;
    }

    &__description {
      font-family: 'Str-Microtext-Regular', sans-serif;
      color: #727272;
      font-size: 16px;
    }

    &__button {
      position: absolute;
      bottom: 16px;
      
      @media #{$mq-small} {
        position: relative;
        bottom: 0px;
        margin-top: 8px;
      }

      div {
        font-family: Str-Microtext-Bold, sans-serif;
        color: #EC0000;

        @media #{$mq-small} {
          font-size: 14px;
        }
      }
    }
  }
}

button {
  background-color: transparent;
  padding: 0px;
  text-align: left;
  border: none;
  color: none;
}

.text-strong {
  font-weight: bold;
  color: #000000;
}

