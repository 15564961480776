@import "../../../breakpoints_home";

.banner-container {
	margin-top: 64px;

	@media #{$mq-mobile} {
		margin-top: 24px;
		max-width: 480px;
	}

}

.banner {
	padding: 34px 0px 64px 0px;
	height: auto;

	@media #{$mq-mobile} {
		padding: 24px 4px 32px 4px;
	}

	&__body {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 48px;


		@media #{$mq-mobile} {
			grid-template-columns: 1fr;
			gap: 24px;
			text-align: center;	
		}

		&__content {
			margin: auto 0px;
			&__title {
				color: #222222;
				font-size: 48px;
				line-height: 56px;
				font-family: "Str-Headline-Bold", sans-serif;

			}

			&__description {
				margin-top: 16px;
				margin-bottom: 40px;
				color: #222222;
				font-size: 18px;
				line-height: 24px;
				font-family: "Str-Microtext-Regular", sans-serif;

				@media #{$mq-tablet} {
					font-size: 16px;
					margin-bottom: 16px;
				}

				@media #{$mq-mobile} {
					font-size: 16px;
					margin-bottom: 24px;
				}
				
			}

			&__button {
				@media #{$mq-mobile} {
					margin-top: 32px;
					margin-bottom: 8px;
				}
			}

			&__footer {
				margin-top: 40px;
				line-height: 0px;
				
				@media #{$mq-tablet} {
					margin-top: 32px;
				}

				@media #{$mq-mobile} {
					display: none;
				}

				&__text {
					color: #727272;
					font-size: 14px;
					line-height: 20px;
					font-family: "Str-Microtext-Regular", sans-serif;
					margin-bottom: 16px;
					margin-right: 8px;
				}

			}

			&__footer-responsive {

				@media #{$mq-mobile} {
					margin-top: 24px;
					display: block;
				}
	
				&__text {
					color: #727272;
					font-size: 14px;
					font-family: "Str-Microtext-Regular", sans-serif;
					margin-bottom: 8px;
				}

				display: none;
			}
		}


		&__logo {
			margin: auto;

			img:first-child {
				width: 100%;
				height: 100%;
			}
		}
	}
}



