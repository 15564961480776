@import '../../breakpoints_new';


.body-promotions {
  margin: 64px auto;
}

.title-promotions {
  font-size: 28px;
  margin-bottom: 48px;
  font-family: Str-Headline-Bold, sans-serif;
  color: #222222;

  @media #{$mq-medium} {
    font-size: 24px;
    margin-bottom: 32px;
  }

  @media #{$mq-small} {
    font-size: 24px;
    margin-bottom: 32px;
    text-align: center;
    line-height: 32px;
  }
}

.body-cards-promotions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  

  @media #{$mq-small} {
    grid-template-columns: repeat(1, 1fr);
    gap: 32px;
  }
}
