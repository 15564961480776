:root {
	--circle-size: clamp(32px, 32px, 32px);
	--spacing: clamp(0.25rem, 2vw, 0.5rem);
}

.caja {
	width: 1160px;
	height: 400px;
	display: flex;
	margin: auto;
}



.Modal.open {
	animation: bounce-in 1s ease-in;
}

.Modal.close {
	animation: bounce-out 1s ease-in;
}

@keyframes bounce-in {
	0% {
		opacity: 0;
		transform: scale(0.5);
	}

	100% {
		opacity: 1;
		transform: scale(1);
	}
}

@keyframes bounce-out {
	0% {
		opacity: 1;
		transform: scale(1);
	}

	100% {
		opacity: 0;
		transform: scale(0.5);
	}
}

.separador-vertical {
	height: 46px;
	width: 1px;
	background: #ECECEC;
	margin: auto 16px;
}

.span-surgir {
	text-decoration: underline;
	text-decoration-thickness: 1px;
	text-decoration-skip-ink: none;
	text-underline-position: from-font;
	color: #EC0000 !important;
	cursor: pointer;

	a {
		color: #EC0000 !important;
	}
}

.title-safe {
	color: #222222;
	font-size: 32px;
	line-height: 36px;
	font-family: "Str-Headline-Bold", sans-serif;
	text-align: center;
	// margin-bottom: 56px;
}

.description-safe {
	font-size: 16px;
	color: #444444;
	font-family: "Str-Microtext-Regular", sans-serif;
	text-align: center;
	margin-top: 12px;
	margin-bottom: 32px;
}

.section-benefits {
	padding: 96px 0px;

	&__cards {
		margin-top: 56px;

		&__card {

			color: #444444;
			font-size: 16px;
			line-height: 24px;
			font-family: "Str-Microtext-Regular", sans-serif;
			height: 174px;
			display: flex;
			align-items: top;
			justify-content: center;

			div {
				text-align: center;
			}

			img {
				width: 64px !important;
				height: 64px;
				// margin: auto;
			}

			p {
				width: 184px;
				padding: 0px 8px;
				// margin: auto;
				margin-top: 12px;
				line-height: 24px;
			}
		}

		.swiper-pagination-bullet-active {
			background-color: #EC0000 !important;
		}

		.swiper-button-next,
		.swiper-button-prev {
			background-color: white;
			background-position: center;
			background-repeat: no-repeat;
			padding: 8px 16px;
			border-radius: 100%;
			border: 1px solid #EC0000;
			width: 48px !important;
			height: 48px !important;
		}

		.swiper-button-prev {
			background-image: url("../../assets/icons/chevron-left-red.svg");
		}

		.swiper-button-next {
			background-image: url("../../assets/icons/chevron-right-red.svg");
		}

		.swiper-button-next::after,
		.swiper-button-prev::after {
			content: "" !important;
		}

	}
}

.section-toppings {
	padding: 96px 0px;
	background-color: #F6FAFC;
	// &__carousel-toppings {
	// 	padding-left: 16px;
	// 	display: grid ;
	// 	justify-content: right
	// }

	&__cards {
		display: grid;
		grid-template-columns: auto auto auto;
		gap: 16px;

		&__card {
			width: 350px;
			margin: 0px auto;
			border-radius: 16px;
			background-color: #FFFFFF;
			padding: 16px;
			display: grid;
			grid-template-columns: auto auto;

			&__icon {
				margin-right: 16px;
			}

			&__content {

				h5 {
					font-family: "Str-Headline-Bold", sans-serif;
					color: #222222;
				}

				p {
					font-size: 14px;
					color: #444444;
					font-family: "Str-Microtext-Regular", sans-serif;
					margin-bottom: 0px;
				}
			}
		}
	}

	&__footer {
		margin-top: 36px;
		text-align: center;
		color: #444444;
		font-family: "Str-Microtext-Regular", sans-serif;
		border-top: 1px solid #E2EFF4;
		padding-top: 48px;

		span {
			color: #23779A;
			text-decoration: underline;
		}
	}
}

table,
th,
td {
	border: none !important;
	margin: 0px;
	border-top-left-radius: 0px;
}

.table>thead>tr>th {
	vertical-align: middle !important;
	// padding-top: 32px !important;
	padding-bottom: 32px !important;
}

.table>tbody>tr>td {
	border-top: 1px solid #CEDEE7 !important;
	padding-top: 32px !important;
	padding-bottom: 32px !important;
	vertical-align: middle !important;
}

.section-plans {
	padding: 96px 0px;
	background-color: #FFFFFF;

	&__cards {
		margin: 56px auto 44px auto;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-gap: 20px;


		&__card {
			border: 1px solid;
			padding: 16px;
			border-radius: 8px;
			text-align: center;
			font-family: "Str-Microtext-Bold", sans-serif;

			&__text {
				margin-bottom: 0px;
				color: #444444 !important;

				span {
					font-family: "Str-Microtext-Regular", sans-serif;
					font-size: 14px;
				}

			}

			&__des {
				color: #444444;
				font-family: "Str-Microtext-Regular", sans-serif;
				font-size: 14px;
				font-weight: 100;
			}
		}

		&__first {
			border-color: #856300;
			color: #856300;
		}

		&__second {

			border-color: #722544;
			color: #722544;
		}

		&__thirth {

			border-color: #137E84;
			color: #137E84;
		}

	}

	&__link {
		display: flex;
		justify-content: center;

		&__body {
			cursor: pointer;
			width: max-content;
			justify-content: center;
			display: flex;
			align-items: center;
			color: #EC0000;

			h6 {
				font-family: "Str-Microtext-Regular", sans-serif;
				margin-bottom: 0px;
				text-decoration: underline;
				text-decoration-thickness: 1px;
				text-decoration-skip-ink: none;
				text-underline-position: from-font;
			}

			span {
				margin-left: 8px;
			}
		}
	}

	&__table {

		&__head {
			&__title {
				color: #222222;
				font-size: 28px;
				font-family: "Str-Headline-Bold", sans-serif;
			}
		}

		&__body {
			&__title {
				display: flex;
				align-items: center;
				color: #444444;
				font-family: "Str-Microtext-Bold", sans-serif;
				// width: max-content;

				img {
					margin-right: 8px;
				}

				span {
					min-width: fit-content;
				}
			}

			&__content {
				text-align: center;
				font-family: "Str-Microtext-Regular", sans-serif;

				&__title {
					font-size: 14px;
					color: #444444;
				}

				&__first {
					color: #856300;
				}

				&__second {
					color: #722544;
				}

				&__thirth {
					color: #137E84;
				}
			}
		}
	}

	&__conditions {
		color: #8F8F8F;
		font-family: "Str-Microtext-Regular", sans-serif;
		font-size: 12px;
		margin-bottom: 76px;
	}

}

.section-operation {
	padding: 96px 0px;
	background-color: #F6FAFC;

	&__body {
		grid-template-columns: 1fr 1fr;
		display: grid;
		grid-gap: 80px;
		margin-top: 56px;

		&__steppers {
			margin: auto;

			ol {
				padding-left: 0pc;
				margin-bottom: 0px;
			}

			&__item {
				position: relative;
				display: flex;
				gap: 1rem;


				&:before {
					content: attr(data-content);
					flex: 0 0 var(--circle-size);
					height: var(--circle-size);
					border-radius: 50%;
					border: 1px solid #EC0000;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 14px;
					color: #444444;
					font-family: "Str-Microtext-Bold", sans-serif;
				}

				&:not(:last-child) {
					&:after {
						content: "";
						position: absolute;
						left: 0;
						top: calc(var(--circle-size) + var(--spacing));
						bottom: var(--spacing);
						// z-index: -1;
						transform: translateX(calc(var(--circle-size) / 2));
						width: 1px;
						background-color: #CCCCCC;
					}

					padding-bottom: 32px;

				}

			}

			&__content {
				color: #444444;

				&__title {
					font-family: "Str-Headline-Bold", sans-serif;

				}

				&__description {
					font-family: "Str-Microtext-Regular", sans-serif;
					line-height: 24px;
					margin-bottom: 0px;
				}
			}
		}

		&__image {
			img {
				width: 100%;
			}
		}
	}
}

.section-protect {
	padding: 96px 0px;

	&__button {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__cards {
		margin-top: 50px;

		&__card {
			background-size: cover;
			height: 360px;
			position: relative;


			&__content {
				position: absolute;
				bottom: 0px;
				padding: 16px;
			}
		}
	}
}

.section-information-safe {
	padding: 96px 0px;

	&__tabs {
		margin-top: 30px;

		&__tab-requirements {
			border: 1px solid #CCCCCC;
			border-radius: 8px;
			padding: 16px;

			ul {
				padding-left: 2rem;
				margin-bottom: 0px;
				font-family: "Str-Microtext-Regular", sans-serif;
				color: #444444;
			}

			li {
				list-style-type: initial;
			}
		}

		&__list-order {
			li {
				list-style-type: auto !important;
			}
		}

		&__list-no-order {
			ul {
				padding-left: 2rem;
				margin-bottom: 0px;
				font-family: "Str-Microtext-Regular", sans-serif;
				color: #444444;
			}

			li {
				list-style-type: initial;
			}
		}

		&__link {
			display: flex;
			margin-top: 28px;

			&__body {
				cursor: pointer;
				width: max-content;
				justify-content: center;
				display: flex;
				align-items: center;
				color: #EC0000;
				border: none;
				background-color: #FFFFFF;

				h6 {
					margin-bottom: 0px;
					text-decoration: underline;
					text-decoration-thickness: 1px;
					text-decoration-skip-ink: none;
					text-underline-position: from-font;
				}

				span {
					margin-left: 8px;
				}
			}
		}
	}

	&__tabs-responsive {
		margin-top: 48px;
	}
}

.accordion-what-we-do-not {
	padding: 96px 0px;
	&__tabs {

		&__tab-requirements {
			border: 1px solid #CCCCCC;
			border-radius: 8px;
			padding: 16px;

			ul {
				padding-left: 2rem;
				margin-bottom: 0px;
				font-family: "Str-Microtext-Regular", sans-serif;
				color: #444444;
			}

			li {
				list-style-type: initial;
			}
		}

		&__list-order {
			li {
				list-style-type: auto !important;
			}
		}

		&__list-no-order {
			ul {
				padding-left: 2rem;
				margin-bottom: 0px;
				font-family: "Str-Microtext-Regular", sans-serif;
				color: #444444;
			}

			li {
				list-style-type: initial;
			}
		}

		&__link {
			display: flex;
			margin-top: 28px;

			&__body {
				cursor: pointer;
				width: max-content;
				justify-content: center;
				display: flex;
				align-items: center;
				color: #EC0000;
				border: none;
				background-color: #FFFFFF;

				h6 {
					margin-bottom: 0px;
					text-decoration: underline;
					text-decoration-thickness: 1px;
					text-decoration-skip-ink: none;
					text-underline-position: from-font;
				}

				span {
					margin-left: 8px;
				}
			}
		}
	}

	&__tabs-responsive {
		margin-top: 48px;
	}
}

.section-contact {

	padding: 60px 0px;

	&__body {
		display: grid;
		grid-template-columns: 1fr 1fr;

		&__content {
			color: #444444;
			max-width: 700px;
			margin: auto 0px;

			&__title {
				font-size: 42px;
				color: #222222;
				font-family: "Str-Headline-Bold", sans-serif;
				line-height: 52px;
			}

			&__description {
				margin: 40px auto 8px auto;
				font-size: 18px;
				line-height: 24px;
				font-family: "Str-Microtext-Regular", sans-serif;
			}

			span {
				font-size: 12px;
			}

			&__span {
				font-size: 24px;
				font-family: "Str-Headline-Bold", sans-serif;
				color: #222222;
			}
		}

		&__image {
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}
}


//MOBILE
@media only screen and (max-width: 585px) {
	.table>thead {
		display: none;
	}

	.table>tbody>tr>td {
		border-top: none !important;
		padding: 0px !important;
	}

	.table>tbody>tr>td:nth-child(1) {
		padding-top: 32px !important;
		padding-bottom: 16px !important;
	}

	.table>tbody>tr:nth-child(even) {
		border-bottom: 1px solid #CEDEE7;
	}

	.table>tbody>tr:nth-child(even):last-child {
		border-bottom: none;
	}

	.table>tbody>tr:nth-child(even)>td {
		padding-bottom: 32px !important;
	}


	.title-safe {
		font-size: 28px;
		padding: 0px 5px;
	}


	.section-benefits {
		padding: 64px 0px;

		&__cards {
			grid-template-columns: 1fr;

			&__card {
				// width: 48px;
			}
		}

	}

	.section-protect {
		padding: 64px 0px;

		&__cards {
			padding-left: 16px;
			width: 100%;
			height: 410px;
			display: grid;
			grid-auto-flow: column;
			overflow-x: scroll;
		}
	}

	.section-operation {
		padding: 64px 0px;

		&__body {
			display: flex;
			flex-direction: column-reverse;
			grid-template-columns: 1fr;
			grid-gap: 56px;

			&__image {
				width: 90%;
				margin: auto;

				img {
					width: 100%;
				}
			}
		}
	}

	.section-contact {
		&__body {
			grid-template-columns: 1fr;
			grid-gap: 32px;

			&__content {
				text-align: center;
				max-width: 270px;
				margin: auto;

				&__title {
					font-size: 28px;
					line-height: 32px;

				}

				// &__description {
				// 	font-size: 16px;
				// }
			}

			&__image {
				width: 232px;
				margin: auto;

				img {
					width: 100%;
				}
			}
		}
	}

	.section-plans {
		padding: 64px 0px;

		&__cards {
			margin: 48px auto 12px auto;

			&__card {
				padding: 8px;
			}
		}
	}

	.section-toppings {
		padding: 64px 0px;

		&__carousel-toppings {
			padding-left: 16px;
		}

		&__footer {
			margin: 48px 18px 0px 18px
		}
	}

	.section-information {
		padding: 64px 0px;
	}

}

@media only screen and (min-width: 320px) and (max-width: 575px) {
	.section-toppings {
		padding: 64px 17px;

		&__cards {
			display: grid;
			grid-template-columns: auto;

			&__card {
				width: auto;
			}
		}
	}

	.section-contact {
		&__body {
			&__content {

				&__description {
					&__phones {
						justify-content: center;
					}
				}

				&__span {
					font-size: 16px;
				}
			}
		}
	}
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
	.section-toppings {
		padding: 64px;

		&__cards {
			display: grid;
			grid-template-columns: auto;

			&__card {
				width: 450px;
			}
		}
	}

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
	.section-toppings {
		padding: 90px 0px;

		&__cards {
			display: grid;
			grid-template-columns: auto auto;

			&__card {
				width: 342px;
			}
		}
	}

}


@media only screen and (min-width: 992px) and (max-width: 1199px) {
	.section-toppings {
		padding: 90px 100px;

		&__cards {
			display: grid;
			grid-template-columns: auto auto;

			&__card {
				width: 342px;
			}

		}
	}
}

@media only screen and (min-width: 2320px) {
	.section-toppings {

		&__carousel-toppings {
			margin-top: 48px;
			justify-content: center
		}

	}
}


@media (max-width: 575px) {
	.caja {
		width: 100%;
		height: 400px;
		display: grid;
		grid-auto-flow: column;
		overflow-x: scroll;
		padding-left: 2%;
		padding-right: 2%;
		margin-top: 20px;
	}
}

@media screen and (min-width: 575px) and (max-width: 767px) {
	.caja {
		width: 100%;
		height: 430px;
		display: grid;
		grid-auto-flow: column;
		overflow-x: scroll;
		padding-left: 2%;
		padding-right: 2%;
		margin-top: 20px;
	}
}

@media screen and (min-width: 767px) and (max-width: 991px) {
	.caja {
		width: 100%;
		height: 430px;
		display: grid;
		grid-auto-flow: column;
		overflow-x: scroll;
		padding-left: 2%;
		padding-right: 2%;
		margin-top: 20px;
	}
}


@media screen and (min-width: 991px) and (max-width: 1199px) {
	.caja {
		width: 100%;
		height: 430px;
		display: grid;
		grid-auto-flow: column;
		overflow-x: scroll;
		padding-left: 2%;
		padding-right: 2%;
		margin-top: 20px;
	}

}